import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import { Link } from 'gatsby'

export default () => (
  <Layout>

    <SEO title="Senior / Lead Front-end Developer"
       description="We are looking for a smart and ambitious developer to take on the responsibilities of leading the front-end development of the Getlocal SAAS e-commerce and site builder." 
       image="/img/people-working.jpg"
       pathname="/jobs/front-end-lead/"
    />
    <Hero 
         headerText="Work with us - Jobs at Getlocal" 
         subHeaderText="We have a team of experienced developers, designers and marketers that help you get started"
         heroBanner="/img/people-working.jpg" /> 

    <section className="section content-pages">
      <div className="container">
        <div className="content intro">
            <div className="columns content is-multiline">
            <div className="column is-12">
                <h3>Senior / Lead Front-end Developer</h3>
                <p>We are looking for a smart and ambitious developer to take on the responsibilities of leading the front-end development of the Getlocal SAAS e-commerce and site builder.</p>
                <p>We were founded in 2016 and we’ve been growing rapidly with customers around the world using our platform to drive their online sales and marketing. Our customers are travel companies and tour operators looking to increase sales and reduce cost.</p>
                <p>We are building a highly scalable solution to be used by thousands of companies and integrating with various API’s.</p>
                <p>You will be leading the architectural design and development of the customer facing backend as well as the consumer facing ecommerce store. You will also be working closely on the product and vision with the management team. </p>
                <p>Fundamental knowledge of web development and a passion for producing fast, high quality web applications is a must.</p>
                <p>We want to build a strong team with different backgrounds and therefore we highly encourage people of diverse backgrounds to apply regardless of age, gender, ethnicity, ability, education or sexual orientation. </p>
                <p>If you are interested in having an impact by being one of the first members in the early stages of a growing startup then hopefully we have the position for you.</p>
                <p>For further information please <Link to="/contact/">contact us</Link>, to apply send an email to belocal@getlocl.com or <Link to="/jobs/apply-now" className="btn">apply now</Link></p>

            </div>
            <div className="margin-above"><Link to="/jobs/" className="btn">See all jobs</Link></div>
        </div>
        </div>
      </div>
    </section>

  </Layout>
)
